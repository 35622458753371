class FAQsPage extends Component {

    static name() {
        return "FAQsPage";
    }

    static componentName() {
        return "FAQsPage";
    }

    data() {
        return {
            activeLink: 'zone'
        };
    }

    getMethods() {
        return {
            scrollMeTo:this.scrollMeTo
        };
    }

    scrollMeTo(refName) {
        this.activeLink = refName;
        var element = this.$refs[refName];
        window.scrollTo({
            top: element.offsetTop,
            behavior: 'smooth'
        });
    }

    getTemplate() {
        return `<div>
                    <headerSectionComponent></headerSectionComponent>
                    <div :class="classContainer" class="static-page px-4 px-md-5">
                        <div class="row justify-content-end">
                            <h5 class="col-12 p-3 mb-4 title underlined-title">{{tr('Frequent asked questions')}}</h5>
                            <nav class="faq-nav nav flex-column col-12 col-md-4 col-lg-3 mb-5 fixed-top">
                                <a class="nav-link" :class="{'active': activeLink == 'zone'}" @click="scrollMeTo('zone')"></a>
                            </nav>
                            <div class="col-12 col-md-8 col-lg-9">
                                <div ref="zone" class="card mb-4 shadow rounded border-0 ml-auto ml-lg-0">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 col-lg-6">
                                                <p class="subtitle"><strong></strong></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

FAQsPage.registerComponent();
